import "./global.css";
import React, { useEffect, useState } from 'react';

import { Link, navigate } from 'gatsby';
import 'aos/dist/aos.css';
import AOS from 'aos';
import animateScrollTo from 'animated-scroll-to';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet';
import { Button, CircularProgress, Divider, Drawer, IconButton, List, ListItem, ListItemText, Stack, styled } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import Footer from '../Footer/Footer';

import imgLogo from './logo2.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { WalletDropdown, WalletListItem } from '../WalletMenu';

import { actions as walletActions } from '../../services/wallet';
import { useHash } from '../../hooks/useHash';


interface Props {
  title: string;
  padding?: boolean;
  loading?: boolean;
}


const Root = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
})

const LogoLink = styled(Link)(({ theme }) => ({
  flexGrow: 1,
  textDecoration: 'none',
  color: theme.palette.text.primary,
  fontSize: 25,
  fontWeight: 500,
}));

const Logo = styled('img')(({ theme }) => ({
  height: 35,
}));

const Spacer = styled('div')({
  flex: 1,
  flexGrow: 1,
});


const Layout: React.FC<Props> = ({ title, padding, loading, children }) => {

  const [ hash, setHash ] = useHash();

  const dispatch = useDispatch();
  const wallet = useSelector((state: RootState) => state.wallet);

  useEffect(() => {
    if (!hash) {
      return;
    }

    scrollTo((hash as string).replace('#', ''));

  }, [ hash ])


  const [ drawerOpen, setDrawerOpen ] = useState(false);

  useEffect(() => {
    AOS.init({
      delay: 300,
      offset: 0,
    });
  }, []);


  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
    target: typeof window !== 'undefined' ? window : undefined,
  });

  const toggleDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollTo = (name: string) => {
    const el = document.querySelector(`.${name}`);
    if (el) {
      setDrawerOpen(false);
      animateScrollTo(el, {
        verticalOffset: -80,
        speed: 250,
      });
    }
  }

  const onLogin = () => {
    dispatch(walletActions.connectWallet({}));
  }


  const navTo = (name: string) => () => {
    const el = document.querySelector(`.${name}`);
    if (!el) {
      navigate(`/#${name}`);
    }
    else {
      (setHash as any)(name);
    }
  }

  return (
    <Root>
      <Helmet>
        <html lang="en" />
        <title>{title} - hayape.com</title>
        <meta property="og:title" content={`${title} - hayape.com`} />
      </Helmet>
      <CssBaseline />

      <AppBar elevation={trigger ? 1 : 0}>
        <Container maxWidth="lg" disableGutters>
          <Toolbar>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer}
              color="inherit"
              sx={{
                mr: 1,
                display: { xs: 'inline-flex', sm: 'none' }
              }}
            >
              <MenuIcon />
            </IconButton>

            <Link to="/">
              <Logo src={imgLogo} />
            </Link>

            <Spacer />


            {/* <LogoLink to="/">
              Hayape
            </LogoLink> */}

            {/* <Typography variant="h6" sx={{ flexGrow: 1 }}>
              
            </Typography> */}
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              sx={{
                display: { xs: 'none', sm: 'flex' }
              }}
            >
              <Button variant="text" color="inherit" component={Link} to="/gazebo">Besedka</Button>
              <Button variant="text" color="inherit" onClick={navTo('accessory-list')}>Accessories</Button>
              <Button variant="text" color="inherit" onClick={navTo('faq-section')}>FAQ</Button>
              <Button variant="text" color="inherit" onClick={navTo('about-section')}>About</Button>
              <WalletDropdown />

            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      <Toolbar />

      <Box sx={{ my: padding ? 2 : 0, flex: 1 }} >
        {
          loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : children
        }
      </Box>


      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar>
          <Typography variant="h6">
            Hayape
          </Typography>
        </Toolbar>
        <Divider />
        <List sx={{ width: 250 }}>
          <WalletListItem />
          <Divider />
          <ListItem button component={Link} to="/gazebo">
            <ListItemText primary="Besedka" />
          </ListItem>
          <ListItem button onClick={navTo('accessory-list')}>
            <ListItemText primary="Accessories" />
          </ListItem>
          <ListItem button onClick={navTo('faq-section')}>
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem button onClick={navTo('about-section')}>
            <ListItemText primary="About" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/terms">
            <ListItemText primary="Terms and Conditions" />
          </ListItem>
          <ListItem button component={Link} to="/privacy">
            <ListItemText primary="Privacy Policy" />
          </ListItem>
        </List>
      </Drawer>

      <Footer />

    </Root>
  );
}


export default Layout;